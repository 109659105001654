import React from "react";

const DescriptionCGU = () => {
  return (
    <section id="descriptionCGU">
      <h3>Définition des parties</h3>
      <p>
        Entre la Société SARL Keranna-Automobiles, <br /> Lieu dit Keranna ,
        29900, Concarneau <br />
        immatriculée au Registre du Commerce et des Sociétés de Concarneau,{" "}
        <br />
        sous le numéro SIRET 92165158400010, <br />
        représentée par Cassandra UHLMANN et Matthieu VIGNEAU en tant que gérant
        de la société, dûment habilité aux fins des présentes. <br />
        La société peut être jointe par email en cliquant sur le formulaire de
        contact accessible via la page d’accueil du site. Ci-après le « Vendeur
        » ou la « Société ». D’une part, Et la personne physique ou morale
        procédant à l’achat de produits ou services de la société, Ci-après, «
        l’Acheteur », ou « le Client » D’autre part,
      </p>
      <br />
      <h3>Preambule</h3>
      <p>
        Le Vendeur est éditeur de produits et de services de commercialisés par
        l’intermédiaire de son site Internet
        (https://www.kerannaautomobiles.fr).
        <br /> La liste et le descriptif des biens et services proposés par la
        Société peuvent être consultés sur le site susmentionné ainsi que ses
        pages de vente.
      </p>
      <h3>Article 1 - Object</h3>
      <p>
        Les présentes Conditions Générales de Vente déterminent les droits et
        obligations des parties dans le cadre de la vente en ligne de Produits
        et de Services proposés par le Vendeur.
      </p>
      <h3>Article 2 - Dispositions Generales</h3>
      <p>
        Les présentes Conditions Générales de Vente (CGV) s’appliquent à toutes
        les ventes de Produits, ou Services effectuées au travers du site
        Internet de la Société et sont partie intégrante du Contrat entre
        l’Acheteur et le Vendeur. <br />
        Le Vendeur se réserve la possibilité de modifier les présentes, à tout
        moment par la publication d’une nouvelle version sur son site Internet.
        <br /> Les CGV applicables alors sont celles étant en vigueur à la date
        du paiement (ou du premier paiement en cas de paiements multiples) de la
        commande. <br />
        La Société s’assure également que leur acceptation soit claire et sans
        réserve au moment de l’achat. Le Client déclare avoir pris connaissance
        de l’ensemble des présentes Conditions Générales de Vente, et le cas
        échéant des Conditions Particulières de Vente liées à un produit ou à un
        service, et les accepter sans restriction ni réserve. <br />
        Le Client reconnaît qu’il a bénéficié des conseils et informations
        nécessaires afin de s’assurer de l’adéquation de l’offre à ses besoins.
        <br />
        Le Client déclare être en mesure de contracter légalement en vertu des
        lois françaises ou valablement représenter la personne physique ou
        morale pour laquelle il s’engage. Sauf preuve contraire les informations
        enregistrées par la Société constituent la preuve de l’ensemble des
        transactions.
      </p>
      <h3>Articles 3 - Prix</h3>
      <p>
        Les prix des produits vendus au travers des sites Internet sont indiqués
        en Euros hors taxes et précisément déterminés sur les pages de
        descriptifs des Produits. Ils sont également indiqués en euros toutes
        taxes comprises (TVA + autres taxes éventuelles) sur la page de commande
        des produits, et hors frais spécifiques d’expédition. Pour tous les
        produits expédiés hors Union européenne et/ou DOM-TOM, le prix est
        calculé hors taxes automatiquement sur la facture. Des droits de douane
        ou autres taxes locales ou droits d’importation ou taxes d’état sont
        susceptibles d’être exigibles dans certains cas. Ces droits et sommes ne
        relèvent pas du ressort du Vendeur. Ils seront à la charge de l’acheteur
        et relèvent de sa responsabilité (déclarations, paiement aux autorités
        compétentes, etc.). Le Vendeur invite à ce titre l’acheteur à se
        renseigner sur ces aspects auprès des autorités locales
        correspondantes.La Société se réserve la possibilité de modifier ses
        prix à tout moment pour l’avenir. Les frais de télécommunication
        nécessaires à l’accès aux sites Internet de la Société sont à la charge
        du Client. Le cas échéant également, les frais de livraison.
      </p>
      <h3>Article 4 - Conclusion du contrat en ligne</h3>
      <p>
        Le Client devra suivre une série d’étapes spécifiques à chaque Produit
        ou Service offert à la vente par le Vendeur pour pouvoir réaliser sa
        commande. Toutefois, les étapes décrites ci-après sont systématiques : –
        Information sur les caractéristiques essentielles du Produit ; – Choix
        du Produit, le cas échéant de ses options et indication des données
        essentielles du Client (identification, adresse…) ; – Acceptation des
        présentes Conditions Générales de Vente. – Vérification des éléments de
        la commande et, le cas échéant, correction des erreurs. – Suivi des
        instructions pour le paiement, et paiement des produits. – Livraison des
        produits. Le Client recevra alors confirmation par courrier électronique
        du paiement de la commande, ainsi qu’un accusé de réception de la
        commande la confirmant. Il recevra un exemplaire .pdf des présentes
        conditions générales de vente. Pour les produits livrés, cette livraison
        se fera à l’adresse indiquée par le Client. Aux fins de bonne
        réalisation de la commande, le Client s’engage à fournir ses éléments
        d’identification véridiques. Le Vendeur se réserve la possibilité de
        refuser la commande, par exemple pour toute demande anormale, réalisée
        de mauvaise foi ou pour tout motif légitime.
      </p>
      <h3>Article 5 - Produits et Services</h3>
      <p>
        Les caractéristiques essentielles des biens, des services et leurs prix
        respectifs sont mis à disposition de l’acheteur sur les sites Internet
        de la société. Le client atteste avoir reçu un détail des frais de
        livraison ainsi que les modalités de paiement, de livraison et
        d’exécution du contrat. Le Vendeur s’engage à honorer la commande du
        Client dans la limite des stocks disponibles uniquement. A défaut, le
        Vendeur en informe le Client. Ces informations contractuelles sont
        présentées en détail et en langue française. Conformément à la loi
        française, elles font l’objet d’un récapitulatif et d’une confirmation
        lors de la validation de la commande. Les parties conviennent que les
        illustrations ou photos des produits offerts à la vente n’ont pas de
        valeur contractuelle. La durée de validité de l’offre des Produits ainsi
        que leurs prix est précisée sur les pages de ventes des produits, ainsi
        que la durée minimale des contrats proposés lorsque ceux-ci portent sur
        une fourniture continue ou périodique de produits ou services. Sauf
        conditions particulières, les droits concédés au titre des présentes le
        sont uniquement à la personne physique signataire de la commande (ou la
        personne titulaire de l’adresse email communiqué). Conformément aux
        dispositions légales en matière de conformité et de vices cachés, le
        Vendeur rembourse ou échange les produits défectueux ou ne correspondant
        pas à la commande. Le remboursement peut être demandé en contactant le
        Vendeur par email ou lettre simple.
      </p>
      <h3>Article 6 - Clause de reserve de propriete</h3>
      <p>
        Les produits demeurent la propriété de la Société jusqu'au complet
        paiement du prix.
      </p>
      <h3>Article 7 - Disponibilité et présentaton</h3>
      <p>
        Les commandes seront traitées dans la limite de nos stocks disponibles
        ou sous réserve des stocks disponibles chez nos fournisseurs.
      </p>
      <h3>Article 8 - Delai de retractation</h3>
      <p>
        Conformément à l’article L. 121-20 du Code de la consommation, « le
        consommateur dispose d’un délai de quatorze jours francs pour exercer
        son droit de rétractation sans avoir à justifier de motifs ni à payer de
        pénalités, à l’exception, le cas échéant, des frais de retour ». « Le
        délai mentionné à l’alinéa précédent court à compter de la réception
        pour les biens ou de l’acceptation de l’offre pour les prestations de
        services ». Le droit de rétractation peut être exercé en contactant la
        Société par email ou téléphone. En cas d’exercice du droit de
        rétractation dans le délai susmentionné, seul le prix du ou des produits
        achetés et les frais d’envoi seront remboursés, les frais de retour
        restent à la charge du Client. Les retours des produits sont à effectuer
        dans leur état d’origine et complets (emballage, accessoires, notice…)
        de sorte qu’ils puissent être recommercialisés à l’état neuf ; ils
        doivent si possible être accompagnés d’une copie du justificatif
        d’achat.
      </p>
      <h3>Article 9 - Garanties</h3>
      <p>
        Conformément à la loi, le Vendeur assume deux garanties : de conformité
        et relative aux vices cachés des produits. Le Vendeur rembourse
        l’acheteur ou échange les produits apparemment défectueux ou ne
        correspondant pas à la commande effectuée. La demande de remboursement
        doit s’effectuer en contactant le Veneur par email ou par lettre simple.
        Le Vendeur rappelle que le consommateur : – dispose d’un délai de 2 ans
        à compter de la délivrance du bien pour agir auprès du Vendeur – qu’il
        peut choisir entre le remplacement et la réparation du bien sous réserve
        des conditions prévues par les dispositions susmentionnées. apparemment
        défectueux ou ne correspondant – qu’il est dispensé d’apporter la preuve
        l’existence du défaut de conformité du bien durant les six mois suivant
        la délivrance du bien. – que, sauf biens d’occasion, ce délai sera porté
        à 24 mois à compter du 18 mars 2016 – que le consommateur peut également
        faire valoir la garantie contre les vices cachés de la chose vendue au
        sens de l’article 1641 du code civil et, dans cette hypothèse, il peut
        choisir entre la résolution de la vente ou une réduction du prix de
        vente (dispositions des articles 1644 du Code Civil).
      </p>
      <h3>Article 10 - Reclamation</h3>
      <p>
        Le cas échéant, l’Acheteur peut présenter toute réclamation en
        contactant la société par email ou par lettre simple.
      </p>
      <h3>Article 11 - Droits de propriete intellectuelle</h3>
      <p>
        Les marques, noms de domaines, produits, logiciels, images, vidéos,
        textes ou plus généralement toute information objet de droits de
        propriété intellectuelle sont et restent la propriété exclusive du
        vendeur. Aucune cession de droits de propriété intellectuelle n’est
        réalisée au travers des présentes CGV. Toute reproduction totale ou
        partielle, modification ou utilisation de ces biens pour quelque motif
        que ce soit est strictement interdite.
      </p>
      <h3>Article 12 - Force Majeure</h3>
      <p>
        L’exécution des obligations du vendeur au terme des présentes est
        suspendue en cas de survenance d’un cas fortuit ou de force majeure qui
        en empêcherait l’exécution. Le vendeur avisera le client de la
        survenance d’un tel évènement dès que possible.
      </p>
      <h3>Article 13 - Nullite et modification du contrat</h3>
      <p>
        Si l’une des stipulations du présent contrat était annulée, cette
        nullité n’entraînerait pas la nullité des autres stipulations qui
        demeureront en vigueur entre les parties. Toute modification
        contractuelle n’est valable qu’après un accord écrit et signé des
        parties.
      </p>
      <h3>Article 14 - RGPD et protection des donnees personnelles</h3>
      <p>
        Conformément au règlement européen en matière de protection des données
        personnelles, vous disposez des droits d’interrogation, d’accès, de
        modification, d’opposition et de rectification sur les données
        personnelles vous concernant. En adhérant à ces conditions générales de
        vente, vous consentez à ce que nous collections et utilisions ces
        données pour l’exacution du présent contrat.
      </p>
      <h3>Article 15 - droit applicable</h3>
      <p>
        Toutes les clauses figurant dans les présentes conditions générales de
        vente, ainsi que toutes les opérations d’achat et de vente qui y sont
        visées, seront soumises au droit français.
      </p>
    </section>
  );
};

export default DescriptionCGU;
