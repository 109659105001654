import React from "react";

const DescriptionPC = () => {
  return (
    <section id="description">
      <h3>Qui nous héberge ?</h3>
      <p>
        Identification des éditeurs et siège social 1&1 Internet SARL 1&1 IONOS{" "}
        <br />
        Cloud GmbH 7, place de la Gare Greifswalder Str. 207 BP 70109 10405{" "}
        <br />
        Berlin, Allemagne 57200 Sarreguemines Cedex <br />
      </p>
      <p>
        <strong>Contact</strong> <br />
        1&1 Internet S.A.R.L.: Vous pouvez joindre notre service clientèle par
        téléphone 24h/24, 7j/7 <br />
        au 0970 808 911 (appel non surtaxé) ou par e-mail à l’adresse suivante:
        info@ionos.fr
      </p>
      <p>
        1&1 IONOS Cloud GmbH: Vous pouvez joindre notre service clientèle par
        téléphone au +49 30 57700 850 ou par e-mail à l’adresse suivante:
        info@cloud.ionos.de
      </p>
      <p>
        <strong>Autres mentions légales</strong> <br />
        1&1 Internet SARL 1&1 IONOS Cloud GmbH SARL au capital de 100 000 EUR{" "}
        <br />
        GmbH au capital de 5 814 171 EUR RCS Sarreguemines B 431 303 775 RCS{" "}
        <br />
        Charlottenburg HRB 125506 B SIRET 431 303 775 000 16 SIRET 29 026 63822{" "}
        <br />
        <strong>Code APE:</strong> 6201Z Identification intracommunautaire FR 13
        431303775 Commerzbank Frankfurt IBAN: DE98 5004 0000 0589 3375 02 BIC:
        COBADEFFXXX (Frankfurt am Main)
      </p>
      <h3>Qui sommes-nous ?</h3>
      <p>l'adresse de notre site est : https://www.kerannaautomobiles.fr</p>

      <h3>Médias</h3>
      <p>
        Si vous téléversez des images sur le site, nous vous conseillons
        d’éviter de téléverser des images contenant des données EXIF de
        coordonnées GPS. <br /> Les personnes visitant votre site peuvent
        télécharger et extraire des données de localisation depuis ces images.
      </p>

      <h3>Contenu embarqué depuis d'autres sites</h3>
      <p>
        Les articles de ce site peuvent inclure des contenus intégrés (par
        exemple des vidéos, images, articles…). Le contenu intégré depuis
        d’autres sites se comporte de la même manière que si le visiteur se
        rendait sur cet autre site.
      </p>
    </section>
  );
};

export default DescriptionPC;
