import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormVoiture from "./FormVoiture";
import { motion } from "framer-motion";
const FormConnexion = () => {
  useEffect(() => {
    axios
      .get("https://82.165.124.173:80/refreshToken")
      .then(() => {
        setHidden(true);
      })
      .catch((e) => {
        console.log(e);
      });
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [msg, setMsg] = useState("");
  const [hidden, setHidden] = useState(false);

  // VERIFICATION TOKEN EXISTANT

  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization =
        "Bearer " + "/" + localStorage.getItem("token");
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  function handleSubmit(event) {
    event.preventDefault();

    axios
      .post("https://82.165.124.173:80/user/connection", { email, password })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        setHidden(true);
      })
      .catch((e) => {
        console.log(e);
        setMsg("email ou Mot de passe incorrect");
      });
  }
  return !hidden ? (
    <section id="connexion">
      <Header />
      <motion.div
        animate={{ scale: 1 }}
        initial={{ scale: 0 }}
        transition={{ delay: 0.9 }}
      >
        <h2>Page de connnexion</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Adresse Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Entrez votre email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Text className="text-muted">
              Cette page est dédié au propriétaire du garage
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Mot de passe</Form.Label>
            <Form.Control
              type="password"
              placeholder="Mot de Passe"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button type="submit">Connexion</Button>
          <div id="msg" hidden={hidden}>
            {msg}
          </div>
        </Form>
      </motion.div>
    </section>
  ) : (
    <FormVoiture />
  );
};

export default FormConnexion;
