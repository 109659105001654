import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import $ from "jquery";
import "../styles/Contact.css";
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_d8ro0ht",
        "template_xpsl5ce",
        form.current,
        "ssQN8vPkCSG-BC7EF"
      )
      .then(
        (result) => {
          console.log(result.text);
          $("#InputSubmit").attr("type", "text");
          $("#InputSubmit").val("Votre message à été envoyé");
          $("#InputSubmit").css("background-color", "green");
        },
        (error) => {
          console.log(error.text);
          $("#InputSubmit").attr("type", "text");
          $("#InputSubmit").val("Une erreur est survenu");
          $("#InputSubmit").css("background-color", "red");
        }
      );
  };
  return (
    <section className="u-clearfix u-white u-section-4" id="contact">
      <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
        <h3>CONTACTEZ NOUS</h3>

        <div className="u-clearfix u-gutter-0 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div
                className="u-align-center u-container-style u-layout-cell u-left-cell u-shape-rectangle u-size-30 u-layout-cell-1"
                data-animation-name="customAnimationIn"
                data-animation-duration="1000"
                data-animation-direction=""
              >
                <div className="u-border-1 u-border-grey-75 u-container-layout u-valign-middle u-container-layout-2">
                  <div className="u-align-left u-form u-form-1">
                    <form
                      onSubmit={sendEmail}
                      ref={form}
                      className="u-clearfix u-form-spacing-30 u-form-vertical u-inner-form"
                      style={{ padding: "0px" }}
                      name="form"
                    >
                      <div className="u-form-email u-form-group u-form-partition-factor-2">
                        <label
                          htmlFor="email-319a"
                          className="u-label u-label-1"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          placeholder="Votre adresse email"
                          id="email-319a"
                          name="user_email"
                          className="u-border-1 u-border-grey-75 u-grey-5 u-input u-input-rectangle u-radius-5 u-input-1"
                          required
                        />
                      </div>
                      <div className="u-form-group u-form-name u-form-partition-factor-2">
                        <label
                          htmlFor="name-319a"
                          className="u-label u-label-2"
                        >
                          Nom
                        </label>
                        <input
                          type="text"
                          placeholder="Votre nom"
                          id="name-319a"
                          name="user_name"
                          className="u-border-1 u-border-grey-75 u-grey-5 u-input u-input-rectangle u-radius-5 u-input-2"
                          required
                        />
                      </div>
                      <div className="u-form-group u-form-message">
                        <label
                          htmlFor="message-319a"
                          className="u-label u-label-3"
                        >
                          Message
                        </label>
                        <textarea
                          placeholder="Entrer votre message"
                          rows="4"
                          cols="50"
                          id="message-319a"
                          name="message"
                          className="u-border-1 u-border-grey-75 u-grey-5 u-input u-input-rectangle u-radius-5 u-input-3"
                          required
                        ></textarea>
                      </div>
                      <div className="u-align-left u-form-group u-form-submit">
                        <input
                          id="InputSubmit"
                          type="submit"
                          className="u-active-grey-60 u-border-none u-btn u-btn-round u-btn-submit  u-hover-grey-60 u-palette-2-dark-1 u-radius-5 u-btn-1"
                        />

                        <br />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="u-align-center u-container-style u-layout-cell u-right-cell u-size-30 u-layout-cell-2"
                data-animation-name="customAnimationIn"
                data-animation-duration="1000"
              >
                <div className="u-border-1 u-border-grey-75 u-container-layout u-container-layout-3">
                  <p className="u-align-center u-text u-text-palette-2-dark-1 u-text-2">
                    Nous retrouver
                  </p>
                  <div
                    className="u-align-center u-grey-10 u-map u-map-1"
                    data-animation-name="customAnimationIn"
                    data-animation-duration="1000"
                  >
                    <div className="embed-responsive">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d874.8396541370864!2d-3.890291993523985!3d47.920915767384585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4810dbf6a864f767%3A0x90528ae7ccb41a7e!2sKeranna%2C%2029900%20Concarneau!5e1!3m2!1sfr!2sfr!4v1672588500375!5m2!1sfr!2sfr"
                        width="600"
                        height="450"
                        allowFullScreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
