import React, { useState } from "react";
import PopUp from "./PopUp";

const Voiture = ({ data }) => {
  const [buttonPopUp, setButtonPopUp] = useState(false);
  return (
    <div>
      <div
        className="u-carousel u-expanded-width-xs u-gallery u-gallery-slider u-layout-carousel u-lightbox u-no-transition u-show-text-none u-gallery-6"
        id="carousel-f46f"
        data-interval="5000"
        data-u-ride="carousel"
      >
        <img src={data.imageUrl[0]} width="340px" />
      </div>
      <h4 style={{ textAlign: "center", margin: "0" }}>
        {data.marque} / {data.modele}
      </h4>
      <div className="cardInfo">
        <p>{data.carburant.toUpperCase()}</p>
        <p>{data.kilometre} km</p>
        <p>{data.annee}</p>
      </div>

      <button
        className="u-align-center u-border-none u-btn  u-dialog-link u-gradient u-none u-text-body-alt-color u-btn-6"
        onClick={() => setButtonPopUp(true)}
      >
        VOIR FICHE TECHNIQUE
      </button>
      <PopUp trigger={buttonPopUp} setTrigger={setButtonPopUp} voiture={data} />

      <p
        className="u-text u-text-default u-text-30"
        style={{ fontSize: "2rem" }}
      >
        {data.prix} €
      </p>
    </div>
  );
};

export default Voiture;
