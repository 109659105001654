export const ListMarqueCar = [
  "Alfa Romeo",
  "Audi",
  "Bmw",
  "Citroën",
  "Dacia",
  "Fiat",
  "Ford",
  "Nissan",
  "Opel",
  "Peugeot",
  "Renault",
  "Seat",
  "Toyota",
  "Volkswagen",
  "Aston Martin",
  "Bentley",
  "Bugatti",
  "Cadillac",
  "Chevrolet",
  "Chrysler",
  "Corvette",
  "Cupra",
  "Dodge",
  "DS",
  "Ferrari",
  "Honda",
  "Hummer",
  "Hyundai",
  "Infiniti",
  "Jaguar",
  "Jeep",
  "Kia",
  "Lamborghini",
  "Lancia",
  "Land Rover",
  "Lexus",
  "Lotus",
  "Maserati",
  "Mazda",
  "MG",
  "Mini",
  "Mitsubishi",
  "Pontiac",
  "Porsche",
  "Rover",
  "Skoda",
  "Subaru",
  "Suzuki",
  "Testla",
  "Volvo",
];
