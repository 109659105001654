import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import $ from "jquery";
import emailjs from "@emailjs/browser";
import img from "../assets/images/facture.webp";
const DevisForm = () => {
  const control = useAnimation();
  const form = useRef();
  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const [ref, inView] = useInView();
  // VARIABLE QUI GERE LE FONCTIONNEMENT DES ANIMATIONS AU SCROLL
  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);
  // VARIABLE QUI GERE LE FONCTIONNEMENT DES ANIMATIONS AU SCROLL

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_d8ro0ht",
        "template_xpsl5ce",
        form.current,
        "ssQN8vPkCSG-BC7EF"
      )
      .then(
        (result) => {
          console.log(result.text);
          $("#InputSubmit").attr("type", "text");
          $("#InputSubmit").val("Votre message à été envoyé");
          $("#InputSubmit").css("background-color", "green");
        },
        (error) => {
          console.log(error.text);
          $("#InputSubmit").attr("type", "text");
          $("#InputSubmit").val("Une erreur est survenu");
          $("#InputSubmit").css("background-color", "red");
        }
      );
  };
  return (
    <section
      className="u-align-center u-clearfix u-grey-10 u-section-2"
      id="sec-f93b"
    >
      <div className="u-clearfix u-sheet u-sheet-1">
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.5 }}
        >
          <h3
            className="u-align-center u-custom-font u-font-oswald u-text u-text-custom-color-3 u-text-1"
            data-animation-name="lightSpeedIn"
            data-animation-duration="1000"
            data-animation-direction=""
          >
            BESOIN D'UN DEVIS ?
          </h3>
        </motion.div>
        <motion.div
          ref={ref}
          animate={control}
          variants={boxVariant}
          initial="hidden"
        >
          <p className="">
            Appelez nous au 02 29 40 89 76, ou remplissez le formulaire
            ci-dessous.
          </p>
          <p className="">
            <strong>
              Attention, pour un devis de pneumatiques, n'oubliez pas de
              mentionner la taille des pneus (ex: 205/55 R16 91V) ainsi que du
              nombre voulu (2 ou 4)
              <br />
              Sans cela nous ne pourrons répondre à votre demande.
            </strong>
          </p>

          <div className="containerForm">
            <div>
              <form
                onSubmit={sendEmail}
                className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form"
                style={{ padding: "10px" }}
              >
                <div className="u-form-group u-form-name u-form-partition-factor-2 u-label-none">
                  <label htmlFor="name-5359" className="u-label">
                    Nom
                  </label>
                  <input
                    type="text"
                    placeholder="Nom"
                    id="name-5359"
                    name="name"
                    className="u-border-1 u-border-grey-30 u-input u-input-rectangle"
                    required
                  />
                </div>
                <div className="u-form-group u-form-partition-factor-2 u-label-none u-form-group-2">
                  <label for="text-6819" className="u-label">
                    Saisir
                  </label>
                  <input
                    type="text"
                    placeholder="Immatriculation"
                    id="text-6819"
                    name="text"
                    className="u-border-1 u-border-grey-30 u-input u-input-rectangle"
                  />
                </div>
                <div className="u-form-group u-form-phone u-label-none u-form-group-3">
                  <label htmlFor="phone-7e30" className="u-label">
                    Téléphone
                  </label>
                  <input
                    type="tel"
                    pattern="\+?\d{0,3}[\s\(\-]?([0-9]{2,3})[\s\)\-]?([\s\-]?)([0-9]{3})[\s\-]?([0-9]{2})[\s\-]?([0-9]{2})"
                    placeholder="Téléphone"
                    id="phone-7e30"
                    name="phone"
                    className="u-border-1 u-border-grey-30 u-input u-input-rectangle"
                    required
                  />
                </div>
                <div className="u-form-email u-form-group u-label-none">
                  <label htmlFor="email-5359" className="u-label">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Email"
                    id="email-5359"
                    name="email"
                    className="u-border-1 u-border-grey-30 u-input u-input-rectangle"
                    required
                  />
                </div>
                <div className="u-form-group u-form-message u-label-none">
                  <textarea
                    placeholder="Devis"
                    rows="4"
                    cols="50"
                    id="message-5359"
                    name="message"
                    className="u-border-1 u-border-grey-30 u-input u-input-rectangle"
                    required=""
                  ></textarea>
                </div>

                <input
                  id="InputSubmit"
                  type="submit"
                  className="u-active-grey-60 u-border-none u-btn u-btn-round u-btn-submit  u-hover-grey-60 u-palette-2-dark-1 u-radius-5 u-btn-1"
                />
              </form>
            </div>

            <div>
              <img className="u-expanded-width u-image " src={img} />
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default DevisForm;
