import React from "react";
import { motion } from "framer-motion";
import Header from "./Header";
import { NavLink } from "react-router-dom";
const VoituresOccasionsTitle = () => {
  return (
    <section
      className="u-align-center u-clearfix u-image u-shading u-section-1 bgc3"
      src=""
      data-image-width="1280"
      data-image-height="853"
      id="sec-c061"
    >
      <Header />
      <div className="u-clearfix u-sheet u-sheet-1">
        <motion.div
          animate={{ scale: 1 }}
          initial={{ scale: 0 }}
          transition={{ delay: 0.5 }}
        >
          <h1
            className="u-custom-font u-font-oswald u-text u-text-default u-text-palette-2-dark-1 u-title u-text-1"
            data-animation-name="customAnimationIn"
            data-animation-duration="1000"
            data-animation-delay="1000"
          >
            VOITURES D'OCCASIONS
          </h1>
          <p
            className="u-large-text u-text u-text-default u-text-variant u-text-2"
            data-animation-name="customAnimationIn"
            data-animation-duration="1000"
            data-animation-delay="1000"
          >
            Ces voitures sont disponibles chez nous !&nbsp;
          </p>
          <NavLink
            to="/voituresoccasions#article"
            className="u-border-none u-btn u-button-style u-grey-75 u-btn-1"
          >
            VOIR LES VOITURES DISPONIBLES
          </NavLink>
        </motion.div>
      </div>
    </section>
  );
};

export default VoituresOccasionsTitle;
