import React from "react";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Galerie from "../components/Galerie";

import Presentation from "../components/Presentation";
import Prestation from "../components/Prestation";
import "../styles/Home.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Home = () => {
  return (
    <React.Fragment>
      <Presentation />
      <Prestation />
      {/* <Galerie /> */}
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
