import React from "react";
import DevisTitle from "../components/DevisTitle";
import DevisForm from "../components/DevisForm";

import Footer from "../components/Footer";
import "../styles/Devis.css";
const Devis = () => {
  return (
    <React.Fragment>
      <DevisTitle />
      <DevisForm />
      <Footer />
    </React.Fragment>
  );
};

export default Devis;
