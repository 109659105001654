import React, { useEffect, useState } from "react";
import $ from "jquery";
const Cookie = () => {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("cookie")) {
      $(".toastie").hide();
    }
  });

  return (
    <section id="cookie-banner" hidden={hidden}>
      <div className="fixed-bottom p-4">
        <div
          className="toastie bg-dark text-white w-100 mw-100"
          role="alert"
          data-autohide="false"
        >
          <div className="toast-body p-4 d-flex flex-column">
            <h4>Cookie </h4>
            <p>
              Nous utilisons des cookies pour vous garantir la meilleure
              expérience sur notre site web. Si vous continuez à utiliser ce
              site, nous supposerons que vous en êtes satisfait.
            </p>
            <div class="ml-auto">
              <button
                type="button"
                className="btn btn-outline-light mr-3"
                id="btnDeny"
                onClick={() => {
                  setHidden(true);
                  localStorage.setItem("cookie", "cookie");
                }}
              >
                Refuser
              </button>
              <button
                type="button"
                className="btn btn-light"
                id="btnAccept"
                onClick={() => setHidden(true)}
              >
                Accepter
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cookie;
