import React from "react";
import { motion } from "framer-motion";
import Header from "../components/Header";
const PresentationPC = () => {
  return (
    <section
      id="presentationPC"
      className="skrollable u-align-center u-clearfix u-image u-parallax u-shading u-section-1 bgc1"
      data-image-width="1600"
      data-image-height="1067"
    >
      <Header />
      <div className="u-clearfix u-sheet u-sheet-1">
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.5 }}
        >
          <h1
            className="u-custom-font u-font-oswald u-text u-text-default u-text-palette-2-dark-1 u-title u-text-1"
            data-animation-name="customAnimationIn"
            data-animation-duration="1000"
            data-animation-direction="X"
            data-animation-delay="1000"
          >
            Politique de Confidentialité
          </h1>
        </motion.div>

        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.7 }}
        >
          <a
            href="/politiqueconfidentialité#description"
            className="u-border-none u-btn u-button-style u-grey-75 u-btn-1"
            data-animation-name="customAnimationIn"
            data-animation-duration="1000"
            data-animation-direction=""
            data-animation-delay="1000"
          >
            Voir ci-dessous
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default PresentationPC;
