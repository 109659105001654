import React from "react";
import { motion } from "framer-motion";
import Header from "./Header";
import Cookie from "./Cookie";

const Presentation = () => {
  return (
    <section
      className=" u-align-center u-clearfix u-image  u-shading u-section-1 bgc1"
      id="accueil"
    >
      <Header />
      <div className="u-clearfix u-sheet u-sheet-1">
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.5 }}
        >
          <h1
            className="u-custom-font u-font-oswald u-text u-text-default u-text-palette-2-dark-1 u-title u-text-1"
            data-animation-name="customAnimationIn"
            data-animation-duration="1000"
            data-animation-direction="X"
            data-animation-delay="1000"
          >
            Mécanique toutes marques
          </h1>
        </motion.div>
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.7 }}
        >
          <p
            className="u-large-text u-text u-text-default u-text-variant u-text-2"
            data-animation-name="customAnimationIn"
            data-animation-duration="1000"
            data-animation-delay="1000"
          >
            ACHAT-VENTE-RÉPARATION-CARROSSERIE&nbsp;
          </p>
        </motion.div>
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.7 }}
        >
          <a
            href="Home.html#prestation"
            className="u-border-none u-btn u-button-style u-grey-75 u-btn-1"
            data-animation-name="customAnimationIn"
            data-animation-duration="1000"
            data-animation-direction=""
            data-animation-delay="1000"
          >
            Voir nos prestations
          </a>
          <Cookie />
        </motion.div>
      </div>
    </section>
  );
};

export default Presentation;
