import React from "react";
import Footer from "../components/Footer";
import GalerieVoiture from "../components/GalerieVoiture";

import VoituresOccasionsTitle from "../components/VoituresOccasionsTitle";
import "../styles/Voiture-Occasion.css";

const VoituresOccasions = () => {
  return (
    <React.Fragment>
      <VoituresOccasionsTitle />
      <GalerieVoiture />
      <Footer />
    </React.Fragment>
  );
};

export default VoituresOccasions;
