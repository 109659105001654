import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import axios from "axios";

import Form from "react-bootstrap/Form";
import { ListMarqueCar } from "../datas/ListMarqueCar";
import "../styles/InsertVoiture.css";

import Row from "react-bootstrap/Row";

const UpdateVoiture = ({ car }) => {
  const [modele, setInputModeleDeLaVoiture] = useState(car.modele);
  const [annee, setInputAnneeDeLaVoiture] = useState(car.annee);
  const [marque, setInputMarqueDeLaVoiture] = useState(car.marque);
  const [kilometre, setInputKmDeLaVoiture] = useState(car.kilometre);
  const [boiteDeVitesse, setInputBoiteDeVitesse] = useState(car.boiteDeVitesse);
  const [carburant, setInputCarburant] = useState(car.carburant);
  const [couleur, setInputCouleur] = useState(car.couleur);
  const [puissanceFiscal, setInputPuissanceFiscal] = useState(
    car.puissanceFiscal
  );
  const [puissanceDIN, setInputPuissanceDIN] = useState(car.puissanceDIN);
  const [nbPlace, setInputNbPlace] = useState(car.nbPlace);
  const [nbPorte, setInputNbPorte] = useState(car.nbPorte);
  const [prix, setInputPrix] = useState(car.prix);

  const [description, setInputDescription] = useState(car.description);

  function handleSubmit(e) {
    e.preventDefault();

    const data = new FormData();

    data.append("modele", modele);
    data.append("annee", annee);
    data.append("marque", marque);
    data.append("kilometre", kilometre);
    data.append("boiteDeVitesse", boiteDeVitesse);
    data.append("carburant", carburant);
    data.append("couleur", couleur);
    data.append("puissanceFiscal", puissanceFiscal);
    data.append("puissanceDIN", puissanceDIN);
    data.append("nbPlace", nbPlace);
    data.append("nbPorte", nbPorte);
    data.append("prix", prix);

    data.append("description", description);
    data.append("idCar", car._id);

    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization =
          "Bearer " + "/" + localStorage.getItem("token");

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios
      .put(`https://82.165.124.173:80/car/update`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => alert("Modification de l'article effectué"))
      .catch((error) => {
        console.log(error);
        alert("Une erreur est survenu");
      });
  }
  return (
    <React.Fragment>
      <section id="InsertVoiture">
        <h2>Modification de l'annonce</h2>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridMarque">
              <Form.Label>Marque</Form.Label>
              <Form.Select
                defaultValue={car.marque}
                onChange={(e) =>
                  setInputMarqueDeLaVoiture(
                    e.target[e.target.selectedIndex].text
                  )
                }
                placeholder={car.marque}
              >
                <option>Choisir la marque</option>
                {ListMarqueCar.sort().map((marque) => (
                  <option value={marque} name={marque}>
                    {marque}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridModèle">
              <Form.Label>Le modèle</Form.Label>
              <Form.Control
                type="text"
                placeholder={car.modele}
                onChange={(e) => setInputModeleDeLaVoiture(e.target.value)}
                defaultValue={car.modele}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridAnnee">
              <Form.Label>L'année</Form.Label>
              <Form.Control
                type="number"
                defaultValue={car.annee}
                placeholder={car.annee}
                onChange={(e) => setInputAnneeDeLaVoiture(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridCouleur">
              <Form.Label>La couleur</Form.Label>
              <Form.Control
                type="text"
                placeholder={car.couleur}
                onChange={(e) => setInputCouleur(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Form.Group as={Col} controlId="formGridCarburant">
            <Form.Label>Boite de vitesse</Form.Label>
            <Form.Select
              defaultValue={car.boiteDeVitesse}
              onChange={(e) => setInputBoiteDeVitesse(e.target.value)}
            >
              <option>Choisir la boite de vitesse</option>
              <option value="Automatique" name="Automatique">
                Automatique
              </option>
              <option value="Manuelle" name="Manuelle">
                Manuelle
              </option>
            </Form.Select>
            <Form.Label>Carburant</Form.Label>
            <Form.Select
              defaultValue={car.carburant}
              onChange={(e) => setInputCarburant(e.target.value)}
            >
              <option>Choisir le carburant</option>
              <option value="Essence" name="Essence">
                Essence
              </option>
              <option value="Diesel" name="Diesel">
                Diesel
              </option>
              <option value="Electrique" name="Electrique">
                Electrique
              </option>
              <option value="Hybrid" name="Hybrid">
                Hybrid
              </option>
            </Form.Select>
          </Form.Group>
          <br />
          <Row className="mb-2">
            <Form.Group
              as={Col}
              className="mb-3"
              controlId="formGridPuissanceFiscal"
            >
              <Form.Label>Puissance Fiscal</Form.Label>
              <Form.Control
                placeholder={car.puissanceFiscal}
                type="number"
                onChange={(e) => setInputPuissanceFiscal(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPuissanceDIN">
              <Form.Label>PuissanceDIN</Form.Label>
              <Form.Control
                type="number"
                placeholder={car.puissanceDIN}
                onChange={(e) => setInputPuissanceDIN(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridKilometre">
              <Form.Label>Nombre de kilomètre</Form.Label>
              <Form.Control
                type="number"
                defaultValue={car.kilometre}
                placeholder={car.kilometre}
                onChange={(e) => setInputKmDeLaVoiture(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} controlId="formGridNbPorte">
              <Form.Label>Nombre de porte</Form.Label>
              <Form.Control
                type="number"
                placeholder={car.nbPorte}
                onChange={(e) => setInputNbPorte(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridNbPlace">
              <Form.Label>Nombre de place</Form.Label>
              <Form.Control
                type="number"
                placeholder={car.nbPlace}
                onChange={(e) => setInputNbPlace(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description de l'annonce</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={(e) => setInputDescription(e.target.value)}
              placeholder={car.description}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGridPrix">
            <Form.Label>Prix</Form.Label>
            <Form.Control
              placeholder={car.prix}
              type="number"
              onChange={(e) => setInputPrix(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Modifier l'annonce
          </Button>
        </Form>
      </section>
    </React.Fragment>
  );
};

export default UpdateVoiture;
