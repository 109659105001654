import React, { useState } from "react";
import { motion } from "framer-motion";
import "../styles/popup.css";

import boitedevitesse from "../assets/images/boitedevitesse.png";
import carburant from "../assets/images/carburant.png";
import couleur from "../assets/images/couleur.png";
import moteur from "../assets/images/moteur.png";
import porte from "../assets/images/porte.png";
import siege from "../assets/images/siege.png";
import VoiturePhoto from "./VoiturePhoto";

const PopUp = (props) => {
  const [buttonPopUp, setButtonPopUp] = useState(false);
  return props.trigger ? (
    <div className="popup">
      <motion.div
        animate={{ scale: 1 }}
        initial={{ scale: 0 }}
        transition={{ delay: 0.2 }}
      >
        <div className="popup-inner">
          <button className="close-btn" onClick={() => props.setTrigger(false)}>
            Fermer
          </button>

          <div className="container">
            <div className="left">
              <img src={props.voiture.imageUrl[0]} width="85%" />

              <div className="top">
                <p>Année: {props.voiture.annee}</p>
                <p className="btnPopUp">{props.voiture.prix}€</p>
                <p> {props.voiture.kilometre} km</p>
              </div>

              <div className="bottom">
                <div className="list">
                  <ul>
                    <div className="item">
                      {<img src={boitedevitesse} height="16px" />}
                      <span>Boite de Vitesse</span>
                      <li>
                        <strong> {props.voiture.boiteDeVitesse}</strong>
                      </li>
                    </div>
                    <div className="item">
                      {<img src={carburant} height="16px" />}
                      <span>Carburant</span>
                      <li>
                        <strong>{props.voiture.carburant}</strong>
                      </li>
                    </div>
                    <div className="item">
                      {<img src={couleur} height="16px" />}
                      <span>Couleur</span>
                      <li>
                        <strong>{props.voiture.couleur}</strong>
                      </li>
                    </div>
                    <div className="item">
                      {<img src={moteur} height="16px" />}
                      <span>Puissance Fiscale</span>
                      <li>
                        <strong> {props.voiture.puissanceFiscal} Cv</strong>
                      </li>
                    </div>
                  </ul>
                  <ul>
                    <div className="item">
                      {<img src={moteur} height="16px" />}
                      <span>Puissance (DIN)</span>
                      <li>
                        <strong> {props.voiture.puissanceDIN} Ch</strong>
                      </li>
                    </div>

                    <div className="item">
                      {<img src={porte} height="16px" />}
                      <span>Portes</span>
                      <li>
                        <strong> {props.voiture.nbPorte}</strong>
                      </li>
                    </div>

                    <div className="item">
                      {<img src={siege} height="16px" />}
                      <span>Places</span>
                      <li>
                        <strong> {props.voiture.nbPlace}</strong>
                      </li>
                    </div>
                  </ul>
                </div>
                <p style={{ color: "#a35559" }}>
                  <strong>Appelez moi au : 02.29.40.89.76</strong>
                </p>
              </div>
            </div>
            <div className="right">
              <h2>
                <strong>Description</strong>
              </h2>
              <p>{props.voiture.description}</p>
              <button className="btnPopUp" onClick={() => setButtonPopUp(true)}>
                Voir plus de photo
              </button>
              <VoiturePhoto
                trigger={buttonPopUp}
                setTrigger={setButtonPopUp}
                images={props.voiture.imageUrl}
              />
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  ) : (
    ""
  );
};

export default PopUp;
