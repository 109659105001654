import React, { useEffect } from "react";

import "../styles/PolitiqueConfidentialité.css";
import PresentationPC from "../components/PresentationPC";
import DescriptionPC from "../components/DescriptionPC";
import Footer from "../components/Footer";
const PolitiqueConfidentialité = () => {
  useEffect(() => {
    require("../styles/Home.css");
  });
  return (
    <section id="PolitiqueConfidentialité">
      <PresentationPC />
      <DescriptionPC />
      <Footer />
    </section>
  );
};

export default PolitiqueConfidentialité;
