import React from "react";
import "../styles/VoiturePhoto.css";
const VoiturePhoto = (props) => {
  return props.trigger ? (
    <section id="sectionPhoto">
      <button className="close-btn" onClick={() => props.setTrigger(false)}>
        Fermer
      </button>
      <div>
        {props.images.map((image, index) => (
          <a href={image} target="_blank">
            <img key={index} src={image} height="200px" />
          </a>
        ))}
      </div>
    </section>
  ) : (
    ""
  );
};

export default VoiturePhoto;
