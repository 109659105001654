import React, { useEffect } from "react";
import DescriptionCGU from "../components/DescriptionCGU";

import PresentationCGU from "../components/PresentationCGU";
import Footer from "../components/Footer";
import "../styles/PolitiqueConfidentialité.css";
const Cgu = () => {
  useEffect(() => {
    require("../styles/Home.css");
  });
  return (
    <section id="cgu">
      <PresentationCGU />
      <DescriptionCGU />
      <Footer />
    </section>
  );
};

export default Cgu;
