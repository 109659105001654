import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "../styles/Header.css";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header id="header">
      <motion.div
        animate={{ scale: 1 }}
        initial={{ scale: 0 }}
        transition={{ delay: 0.5 }}
      >
        <h1>SARL KERANNA AUTOMOBILES</h1>

        <nav>
          <ul>
            <li>
              <NavLink to="/">Accueil</NavLink>
            </li>
            <li>
              <NavLink to="/devis">Devis</NavLink>
            </li>
            <li>
              <NavLink to="/voituresoccasions">Voitures d'occasions</NavLink>
            </li>
            <li>
              <NavLink to="/#contact">Contact</NavLink>
            </li>
            {localStorage.getItem("ip") === "true" && (
              <li>
                <NavLink to="/connexion">
                  <FontAwesomeIcon icon={faUser} />
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      </motion.div>
    </header>
  );
};

export default Header;
