import React from "react";
import { motion } from "framer-motion";
import Header from "./Header";

const DevisTitle = () => {
  return (
    <section
      className="skrollable u-align-center u-clearfix u-image u-parallax u-shading u-section-1 bgc2"
      src=""
      data-image-width="720"
      data-image-height="480"
      id="sec-684f"
    >
      <Header />
      <div className="u-clearfix u-sheet u-sheet-1">
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.5 }}
        >
          <h2 className="u-custom-font u-font-oswald u-text u-text-default u-text-palette-2-dark-1 u-title u-text-1">
            DEVIS
          </h2>
        </motion.div>
      </div>
    </section>
  );
};

export default DevisTitle;
