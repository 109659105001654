import React, { useState, useEffect } from "react";
import Voiture from "./Voiture";
import axios from "axios";
const GalerieVoiture = () => {
  const [data, setData] = useState();
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    axios.get("https://82.165.124.173:80/car/find").then((res) => {
      setData(res.data.cars);
      if (res.data.cars[0] != undefined) {
        setIsEmpty(true);
      }
    });
  }, []);

  return isEmpty ? (
    <section className="u-clearfix u-section-2" id="article">
      <div className="containerVoiture">
        {data.map((Datavoiture) => (
          <Voiture data={Datavoiture} />
        ))}
      </div>
    </section>
  ) : (
    <section className="u-clearfix u-section-2" id="article">
      <h2 style={{ textAlign: "center", padding: "40px" }}>
        Nous n'avons pas de véhicule à vendre actuellement
      </h2>
    </section>
  );
};

export default GalerieVoiture;
