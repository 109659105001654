import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useEffect } from "react";

import Home from "./pages/Home";
import Devis from "./pages/Devis";
import VoituresOccasions from "./pages/VoituresOccasions";
import Connexion from "./pages/Connexion";
import PolitiqueConfidentialité from "./pages/PolitiqueConfidentialité";
import Cgu from "./pages/Cgu";
import axios from "axios";
function App() {
  useEffect(() => {
    axios
      .get("https://82.165.124.173:80/getIp", {})
      .then((res) => {
        localStorage.setItem("ip", res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/devis" element={<Devis />} />
        <Route path="/voituresoccasions" element={<VoituresOccasions />} />
        {localStorage.getItem("ip") === "true" && (
          <Route path="/connexion" element={<Connexion />} />
        )}

        <Route
          path="/politiqueconfidentialité"
          element={<PolitiqueConfidentialité />}
        />
        <Route path="/cgu" element={<Cgu />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
