import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import "../styles/footer.css";
const Footer = () => {
  return (
    <footer>
      <div>
        <h4>Horaires d'ouvertures</h4>
        <p>Du lundi au vendredi :</p>
        <p>8H 12H - 14h 18h</p>
      </div>

      <div>
        <h4>Coordonnées</h4>
        <ul>
          <li>
            <FontAwesomeIcon icon={faPhone} /> &nbsp; 02 29 40 89 76
          </li>
          <br />
          <li>
            <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
            atelier@keranna-automobiles.fr
          </li>
          <br />

          <li>
            <FontAwesomeIcon icon={faMapLocationDot} /> &nbsp; Lieu dit Keranna
            29900 Concarneau
          </li>
        </ul>
      </div>
      <div>
        <h4>Mentions Légales</h4>
        <ul>
          <li>
            <NavLink to="/politiqueconfidentialité" rel="noopener noreferrer">
              Politique de confidentialité
            </NavLink>
          </li>
          <br />
          <li>
            <NavLink to="/cgu" rel="noopener noreferrer">
              Conditions générales d'utilisations
            </NavLink>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
