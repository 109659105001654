import React from "react";
import { ListMarqueCar } from "../datas/ListMarqueCar";

import "../styles/InsertVoiture.css";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
const InsertVoiture = () => {
  return (
    <React.Fragment>
      <section id="InsertVoiture">
        <h2>Nouvelle annonce</h2>
        <Form
          action="https://82.165.124.173:80/car/create"
          method="POST"
          enctype="multipart/form-data"
        >
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridMarque">
              <Form.Label>Marque</Form.Label>
              <Form.Select
                defaultValue="Choisir la marque"
                required
                name="marque"
              >
                <option>Choisir la marque</option>
                {ListMarqueCar.sort().map((marque) => (
                  <option value={marque} name={marque}>
                    {marque}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridModèle">
              <Form.Label>Le modèle</Form.Label>
              <Form.Control
                type="text"
                name="modele"
                placeholder="Entrer le modèle de la voiture"
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridAnnee">
              <Form.Label>L'année</Form.Label>
              <Form.Control
                type="number"
                name="annee"
                placeholder="Entrer l'année de la voiture"
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridCouleur">
              <Form.Label>La couleur</Form.Label>
              <Form.Control
                type="text"
                name="couleur"
                placeholder="Entrer la couleur de la voiture"
                required
              />
            </Form.Group>
          </Row>

          <Form.Group as={Col} controlId="formGridCarburant">
            <Form.Label>Boite de vitesse</Form.Label>
            <Form.Select
              defaultValue="Choisir la boite de vitesse"
              name="boiteDeVitesse"
              required
            >
              <option>Choisir la boite de vitesse</option>
              <option value="Automatique" name="Automatique">
                Automatique
              </option>
              <option value="Manuelle" name="Manuelle">
                Manuelle
              </option>
            </Form.Select>
            <Form.Label>Carburant</Form.Label>
            <Form.Select
              defaultValue="Choisir le carburant"
              required
              name="carburant"
            >
              <option>Choisir le carburant</option>
              <option value="Essence" name="Essence">
                Essence
              </option>
              <option value="Diesel" name="Diesel">
                Diesel
              </option>
              <option value="Electrique" name="Electrique">
                Electrique
              </option>
              <option value="Hybrid" name="Hybrid">
                Hybrid
              </option>
              <option value="Gpl" name="Gpl">
                Gpl
              </option>
            </Form.Select>
          </Form.Group>
          <br />
          <Row className="mb-2">
            <Form.Group
              as={Col}
              className="mb-3"
              controlId="formGridPuissanceFiscal"
            >
              <Form.Label>Puissance Fiscal</Form.Label>
              <Form.Control
                required
                name="puissanceFiscal"
                placeholder="Entrer la puissance fiscal"
                type="number"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPuissanceDIN">
              <Form.Label>PuissanceDIN</Form.Label>
              <Form.Control
                type="number"
                placeholder="Entrer la puissance DIN"
                required
                name="puissanceDIN"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridKilometre">
              <Form.Label>Nombre de kilomètre</Form.Label>
              <Form.Control
                type="number"
                placeholder="Entrer le kilomètre de la voiture"
                required
                name="kilometre"
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} controlId="formGridNbPorte">
              <Form.Label>Nombre de porte</Form.Label>
              <Form.Control
                type="number"
                placeholder="Entrer le nombre de porte"
                required
                name="nbPorte"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridNbPlace">
              <Form.Label>Nombre de place</Form.Label>
              <Form.Control
                type="number"
                placeholder="Entrer le nombre de place"
                required
                name="nbPlace"
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description de l'annonce</Form.Label>
            <Form.Control as="textarea" rows={3} required name="description" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGridPrix">
            <Form.Label>Prix</Form.Label>
            <Form.Control
              placeholder="Entrer le prix"
              type="number"
              name="prix"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGridPhoto">
            <Form.Label>Photos</Form.Label>
            <Form.Control type="file" name="images" multiple required />
          </Form.Group>

          <Button type="submit">Créer</Button>
        </Form>
      </section>
    </React.Fragment>
  );
};

export default InsertVoiture;
