import React, { useState, useEffect } from "react";
import InsertVoiture from "./InsertVoiture";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "../styles/FormVoiture.css";
import "../styles/Voiture-Occasion.css";
import UpdateVoiture from "./UpdateVoiture";
import Header from "../components/Header";
const FormVoiture = () => {
  const [action, setAction] = useState("");
  const [data, setData] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [cptStock, setCptStock] = useState();
  const [index, setIndex] = useState();

  useEffect(() => {
    axios.get("https://82.165.124.173:80/car/find").then((res) => {
      setCptStock(res.data.cars.length);
      setData(res.data.cars);
      if (res.data.cars[0] != undefined) {
        setIsEmpty(true);
      }
    });
  }, []);

  function handleClick(name, data) {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization =
          "Bearer " + "/" + localStorage.getItem("token");

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    if (name == "delete") {
      axios
        .delete(`https://82.165.124.173:80/car/delete/`, {
          data: { idCar: data },
        })
        .then(() => alert("Annonce supprimé !"))
        .catch(() => alert("Une érreur est survenu"));
    }
  }

  {
    if (action == "insert") {
      return <InsertVoiture />;
    } else if (action == "update") {
      return <UpdateVoiture car={data[index]} />;
    }
  }
  return (
    <React.Fragment>
      <section id="FormVoiture">
        <Header />
        <h2>Tableau de bord</h2>
        <h3 style={{ color: "white" }}>Voiture en stock : {cptStock}</h3>
        <Button
          type="submit"
          onClick={(e) => setAction(e.currentTarget.name)}
          name="insert"
        >
          Créer une annonce
        </Button>
        {isEmpty ? (
          <section className="u-clearfix u-section-2" id="article">
            <div className="containerVoiture" style={{ width: "70%" }}>
              {data.map((Datavoiture, index) => (
                <div key={index}>
                  <img
                    height="241px"
                    width="100%"
                    src={Datavoiture.imageUrl[0]}
                  />
                  <button
                    onClick={(e) => {
                      setAction(e.target.name);
                      setIndex(index);
                    }}
                    name="update"
                  >
                    Modifier
                  </button>
                  <button
                    onClick={(e) => handleClick(e.target.name, Datavoiture._id)}
                    name="delete"
                  >
                    Supprimer
                  </button>
                </div>
              ))}
            </div>
          </section>
        ) : (
          <h2> Aucun véhicule en stock</h2>
        )}
      </section>
    </React.Fragment>
  );
};

export default FormVoiture;
