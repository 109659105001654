import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { NavLink } from "react-router-dom";
// IMAGE PRESTATION
import img1 from "../assets/images/1743705-3831398f.png";
import img2 from "../assets/images/2825598-e2ce4d35.png";
import img3 from "../assets/images/2061866-c3279deb.png";
import img4 from "../assets/images/4257483-601e2c86.png";
import img5 from "../assets/images/turbo.png";

import img7 from "../assets/images/2417234-03448fda.png";

// IMAGE PRESTATION

import "../styles/Prestation.css";

const Prestation = () => {
  const control = useAnimation();
  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const [ref, inView] = useInView();
  // VARIABLE QUI GERE LE FONCTIONNEMENT DES ANIMATIONS AU SCROLL
  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);
  // VARIABLE QUI GERE LE FONCTIONNEMENT DES ANIMATIONS AU SCROLL
  return (
    <section id="prestation">
      <h2>NOUS INTERVENONS POUR...</h2>

      <div className="u-border-3 u-border-grey-dark-1 u-line u-line-horizontal u-line-1"></div>
      <motion.div
        ref={ref}
        animate={control}
        variants={boxVariant}
        initial="hidden"
        className="containerPrestation"
      >
        <div>
          <span>
            <img src={img1} alt="" height="80px" />
          </span>
          <h4>Entretiens et Diagnostics toutes marques</h4>
        </div>

        <div>
          <span>
            <img src={img2} alt="" height="80px" />
          </span>
          <h4>Remplacement de toutes chaines de distributions&nbsp;</h4>
        </div>

        <div>
          <span>
            <img src={img3} alt="" height="80px" />
          </span>
          <h4>Changement de moteurs d'occasions ou échange standard</h4>
        </div>

        <div>
          <span>
            <img src={img4} alt="" height="80px" />
          </span>
          <h4>Entretien boite manuelle et boite auto</h4>
        </div>

        <div>
          <span>
            <img src={img5} alt="" height="80px" />
          </span>
          <h4>Remplacement injection turbo</h4>
        </div>

        <div>
          <span>
            <img src={img7} alt="" height="80px" />
          </span>
          <h4>Reprise et vente de véhicules toutes marques</h4>
        </div>
      </motion.div>

      <motion.div
        ref={ref}
        animate={control}
        variants={boxVariant}
        initial="hidden"
        style={{ margin: "40px 0", textAlign: "center" }}
      >
        <NavLink to="/devis" id="btnDevis">
          Je veux un devis !
        </NavLink>
      </motion.div>

      <div className="u-border-3 u-border-grey-dark-1 u-line u-line-horizontal u-line-2"></div>
    </section>
  );
};

export default Prestation;
