import React from "react";
import FormConnexion from "../components/FormConnexion";

import "../styles/Connexion.css";
import "bootstrap/dist/css/bootstrap.min.css";
const Connexion = () => {
  return (
    <React.Fragment>
      <FormConnexion />
    </React.Fragment>
  );
};

export default Connexion;
